import React, { useEffect } from 'react';
import maplibregl from 'maplibre-gl';

function FloodsLayer_MB({ map, url, lyr }) {
  useEffect(() => {
    // Check if `url` is a string with more than 1 character
    if (typeof url === 'string' && url.length > 1) {
      // Add the vector tile source for MBTiles using the provided URL
      map.addSource('floods_MB', {
        type: 'vector',
        tiles: [url],
        minzoom: 0,
        maxzoom: 14,
      });
    } else {
      // Default vector tile source if `url` is not valid
      map.addSource('floods_MB', {
        type: 'vector',
        tiles: ['https://api.maptiler.com/tiles/80d7c3c3-f391-4716-b0a1-a936118595fe/{z}/{x}/{y}.pbf?key=lqzGPutejx80TSYrzflb'],
        minzoom: 0,
        maxzoom: 14,
      });
    }

    // Determine the source-layer to use
    const sourceLayer = (typeof lyr === 'string' && lyr.length > 1) ? lyr : 'floods';

    // Add the fill layer
    map.addLayer({
      id: 'floods_MB',
      type: 'fill',
      source: 'floods_MB',
      'source-layer': sourceLayer,
      paint: {
        'fill-color': 'rgba(0, 0, 255, 0.1)',
        'fill-outline-color': '#777',
      },
    });

    // Initialize a popup
    const popup = new maplibregl.Popup({
      closeButton: false,
      closeOnClick: false,
      maxWidth: 'auto',
    });

    // Add hover interactivity to the layer
    map.on('mousemove', 'floods_MB', (e) => {
      const id_field = getDynamicIdField();
      const features = map.queryRenderedFeatures(e.point, { layers: ['floods_MB'] });
      if (features.length > 0) {
        const feature = features[0];
        const hoveredFeatureId = feature.properties[id_field]; // Use dynamic id_field

        // Change fill color of the hovered feature
        map.setPaintProperty('floods_MB', 'fill-color', [
          'case',
          ['==', ['get', id_field], hoveredFeatureId],
          'rgba(255, 0, 0, 0.5)', // Red color for hovered feature
          'rgba(0, 0, 255, 0.1)', // Default color for other features
        ]); 

        // Display popup on hover
        popup.setLngLat(e.lngLat)
          .setHTML(renderPopupContent(feature))
          .addTo(map);
      }
    });

    let mouseLeftTimer; // Variable to store the timer ID
    map.on('mouseleave', 'floods_MB', () => {
      // Start a timer to delay the action by 1 second
      mouseLeftTimer = setTimeout(() => {
        // Reset fill color of all features to default after 1 second
        map.setPaintProperty('floods_MB', 'fill-color', 'rgba(0, 0, 255, 0.1)');
        // Remove popup on mouse leave
        popup.remove();
      }, 1000); // 1000 milliseconds (1 second)
    });

    // Cancel the timer if mouse re-enters the mapped area before the timeout
    map.on('mouseenter', 'floods_MB', () => {
      clearTimeout(mouseLeftTimer); // Clear the timer
    });

    // Clean up function when component unmounts
    return () => {
      if (map.getLayer('floods_MB')) {
        map.removeLayer('floods_MB');
      }

      if (map.getSource('floods_MB')) {
        map.removeSource('floods_MB');
      }
    };
  }, [map, url, lyr]);

  // Function to get the dynamic id_field based on the attribute containing 'id'
  const getDynamicIdField = () => {
    const attributeNames = listAttributeNames();
    const idField = attributeNames.find(name => name.toLowerCase().includes('id'));
    if (idField) {
      console.log(`Using id field: ${idField}`);
      return idField;
    }
    console.log('No attribute containing "id" found');
    return attributeNames[0]; // Fallback to the first attribute if no match
  };

  // Function to list all attribute names of the data found
  const listAttributeNames = () => {
    // Query a feature to get its properties
    const features = map.queryRenderedFeatures({ layers: ['floods_MB'] });
    if (features.length > 0) {
      const feature = features[0];
      const attributeNames = Object.keys(feature.properties);
      console.log('Attribute Names:', attributeNames);
      return attributeNames;
    }
    console.log('No features found');
    return [];
  };
  
  // Function to render popup content
  const renderPopupContent = (feature) => {
    const { properties } = feature;
    if (!properties) return ''; // Return empty string if no properties are available
  
    // Generate HTML content for popup with all properties
    let contentHTML = '<div>';
    contentHTML += '<h3>Properties</h3>';
    
    // Loop through each property and add to the popup content
    Object.entries(properties).forEach(([key, value]) => {
      contentHTML += `<p>${key}: ${value}</p>`;
    });
  
    contentHTML += '</div>';
  
    return contentHTML;
  };

  return null; // This component does not render anything directly
}

export default FloodsLayer_MB;
