import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  faLayerGroup,
  faChevronDown,
  faChevronUp,
  faWater,
  faMap,
  faGear,
} from '@fortawesome/pro-solid-svg-icons';
import { Icon, Button, Toggle } from '@nazka/nazka.mapframe.components';

import './layer-selector.styl';
import BuildingsLayer from '../BuildingsLayer/BuildingsLayer';
import FloodsLayer_PM from '../FloodsLayers/FloodsLayer_PM';
import FloodsLayer_GVT from '../FloodsLayers/FloodsLayer_GVT';
import FloodsLayer_MB from '../FloodsLayers/FloodsLayer_MB';
import FloodsLayer_EL from '../FloodsLayers/FloodsLayer_EL';
import InputData_GVT from '../InputData/InputData_GVT';

import InputData_MB from '../InputData/InputData_MB';
import InputData_PM from '../InputData/InputData_PM';
import InputPath from '../InputData/InputPath';
import InputToken from '../InputData/InputToken';
import InputLyrMB from '../InputData/InputLyrMB';
import InputLyrPM from '../InputData/InputLyrPM';

function LayerSelector(props) {
  const [isOpen, setIsOpen] = useState(true);
  const [showFloods_PM, setShowFloods_PM] = useState(false);
  const [showFloods_GVT, setShowFloods_GVT] = useState(false);
  const [showFloods_MB, setShowFloods_MB] = useState(false);
  const [showFloods_EL, setShowFloods_EL] = useState(false);

  const [showBuildings, setShowBuildings] = useState(false);

  const [dataURL_GVT, setDataURL_GVT] = useState('');
  const [dataURL_MB, setDataURL_MB] = useState('');
  const [dataURL_PM, setDataURL_PM] = useState('');
  const [Path, setPath] = useState('');
  const [Token, setToken] = useState('');
  const [LyrMB, setLyrMB] = useState('');
  const [LyrPM, setLyrPM] = useState('');

  const map = useSelector(state => state.maplibreMap.map);

  const toggleShowBuildings = (e) => {
    setShowBuildings(e.target.checked);
  };
  const toggleShowFloods_PM = (e) => {
    setShowFloods_PM(e.target.checked);
  };
  const toggleShowFloods_GVT = (e) => {
    setShowFloods_GVT(e.target.checked);
  };
  const toggleShowFloods_MB = (e) => {
    setShowFloods_MB(e.target.checked);
  };

  const toggleShowFloods_EL = (e) => {
    setShowFloods_EL(e.target.checked);
  };

  const toggleIsOpen = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div className={`layer-selector__container ${isOpen ? 'layer-selector__container--expanded' : ''}`}>
      <div className="layer-selector__header__wrapper">
        <div className="layer-selector__header__text__layer__wrapper">
          <Icon icon={faLayerGroup} size="16px" />
          <span>Layers</span>
        </div>
        <Button
          icon={isOpen ? faChevronUp : faChevronDown}
          size="20px"
          onClick={toggleIsOpen}
          color="white"
          hover={false}
        />
      </div>
      <div className={`layer-selector__body__wrapper ${isOpen ? 'expanded' : ''}`}>
        <hr style={{ opacity: 0.1, marginLeft: '10px', marginRight: '10px' }} />
        <div className="layer-selector_GVT">
          <Toggle
            label="geojson-vt"
            name="GVT"
            checked={showFloods_GVT}
            onInputChange={toggleShowFloods_GVT}
          />
          <InputData_GVT
            dataURL_GVT={dataURL_GVT}
            setDataURL_GVT={value => setDataURL_GVT(value)}
            placeholder="geojson url (or default floods)"
            
          />
        </div>

        <div className="layer-selector_PM">
          <Toggle
            label="PMTiles"
            name="PM-tiles"
            checked={showFloods_PM}
            onInputChange={toggleShowFloods_PM}
          />
          <InputData_PM
            dataURL_PM={dataURL_PM}
            setDataURL_PM={value => setDataURL_PM(value)}
            placeholder="PMTiles url (or default floods)"
          />

        </div>
        <div />
        <InputLyrPM
          LyrPM={LyrPM}
          setLyrPM={value => setLyrPM(value)}
          placeholder="Layer name (not required, no input = first layer mapped)"
        />        
        {' '}
        <div className="layer-selector_MB">
          <Toggle
            label="MBTiles"
            name="MB"
            checked={showFloods_MB}
            onInputChange={toggleShowFloods_MB}
          />
          <InputData_MB
            dataURL_MB={dataURL_MB}
            setDataURL_MB={value => setDataURL_MB(value)}
            placeholder="MBTiles url (or default floods)"
          />
        </div>
        <div />
        <InputLyrMB
          LyrMB={LyrMB}
          setLyrMB={value => setLyrMB(value)}
          placeholder="Layer name REQUIRED unless using default url"
        />    

        <div className="layer-selector_MB">
          <Toggle
            label="Ellipsis Drive"
            name="EL"
            checked={showFloods_EL}
            onInputChange={toggleShowFloods_EL}
            
          />
          <InputPath
            Path={Path} 
            setPath={value => setPath(value)}
            placeholder="PathId"
          />
 
        </div>
        <div>

          <InputToken
            Token={Token}
            setToken={value => setToken(value)}
            placeholder="Token"
          />
 
        </div>
        <div>
          <text>
            Expand panel below for tutorial and default urls (white arrow in blue box).
          </text>
        </div>
      </div>
      {map && showFloods_PM && (
        <FloodsLayer_PM map={map} url={dataURL_PM} lyr={LyrPM} />
      )}

      {map && showFloods_GVT && (
      <FloodsLayer_GVT map={map} url={dataURL_GVT} />
      )}   

      {map && showFloods_MB && (
      <FloodsLayer_MB map={map} url={dataURL_MB} lyr={LyrMB} />
      )}

      {map && showFloods_EL && (
      <FloodsLayer_EL map={map} path={Path} token={Token} />
      )}  

      {map && showBuildings && (
        <BuildingsLayer map={map} />
      )}
    </div>
    
  )
}

export default LayerSelector