import { useLocation, NavLink } from 'react-router-dom';

import './navbar.styl';

function Navbar() {
  const location = useLocation();

  return (
    <div className="navbar z-depth-1">
      <span style={{ flex: 1 }} />
      {/* {(location.pathname !== '/') && <NavLink to="/">Map</NavLink>}
      <NavLink to="/about">About</NavLink>
      <NavLink to="/contact">Contact</NavLink> */}
      
    </div>
  )
}

export default Navbar;