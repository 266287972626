import { useEffect } from 'react';

function BuildingsLayer({ map }) {
  useEffect(() => {
    map.addSource('buildings', {
      type: 'vector',
      url: 'pmtiles://https://s3.eu-west-1.amazonaws.com/nazka-mapp.buildspace/buildings.pmtiles',
    });
  
    map.addLayer({
      id: 'buildings',
      type: 'fill',
      source: 'buildings',
      'source-layer': 'buildings',
      paint: {
        'fill-color': 'rgba(255, 0, 0, 0.1)',
        'fill-outline-color': '#777',
      },
    });

    return () => {
      if (map.getLayer('buildings')) {
        map.removeLayer('buildings');
      }
    
      if (map.getSource('buildings')) {
        map.removeSource('buildings');
      }
    };
  }, [map]);

  return null;
}

export default BuildingsLayer;