import { useEffect } from 'react';
import maplibregl from 'maplibre-gl';
import * as pmtiles from 'pmtiles';

function FloodsLayer_PM({ map, url, lyr }) {
  useEffect(() => {
    // Step 1: Check if there is a url. If not, use default.
    if (typeof url !== 'string' || url.length <= 1) {
      // Default vector tile source if `url` is not valid
      url = 'https://raw.githubusercontent.com/RubenCox1/demostage/main/floods2.pmtiles';
    }

    // Step 2: Make protocol of the pmtiles in url.
    const protocol = new pmtiles.Protocol();
    maplibregl.addProtocol('pmtiles', protocol.tile);
    const p = new pmtiles.PMTiles(url);
    // This is so we share one instance across the JS code and the map renderer
    protocol.add(p);

    // Step 3: Get metadata from the PMTiles -> extract first layer (id for source) and first field (id for interactivity)
    let layer_id = lyr; // Initialize with lyr
    let id_field;

    p.getMetadata()
      .then((result) => {
        console.log(result);
        if (!layer_id) { // Only if layer_id is not provided via props
          if (result.tilestats && result.tilestats.layers) {
            const { layers } = result.tilestats;
            layer_id = layers[0].layer;
            console.log(layers);
            console.log(`found in tilestats: ${layer_id}`);
          }

          // Fallback: If tilestats is undefined or no suitable layer found, check vector_layers
          if (!layer_id) {
            if (result.vector_layers && result.vector_layers.length > 0) {
              layer_id = result.vector_layers[0].id;
              console.log(result.vector_layers[0]);
              console.log(`found in vector_layers : ${layer_id}`);
            }
          }

          if (!layer_id) {
            console.error('No suitable layer found in the PMTiles metadata.');
            return;
          }
        }

        console.log(`using layer:${layer_id}`);
        console.log(`using id field:${id_field}`);

        mapping(layer_id, id_field);
      })
      .catch(console.error);

    // Step 4: Use the identified layer id and field id in the 'normal' mapping functions.
    function mapping(layer_id, id_field) {
      map.addSource('floods_PM', {
        type: 'vector',
        url: `pmtiles://${url}`,
      });

      map.addLayer({
        id: 'floods_PM',
        type: 'fill',
        source: 'floods_PM',
        'source-layer': layer_id, // Adjust this to match your source layer name
        paint: {
          'fill-color': 'rgba(0, 0 , 255, 0.1)',
          'fill-outline-color': '#777',
        },
      });

      const popup = new maplibregl.Popup({
        closeButton: false,
        closeOnClick: false,
        maxWidth: 'auto',
      });

      // Add hover interactivity to the layer.
      let mouseLeftTimer; // Variable to store the timer ID

      map.on('mousemove', 'floods_PM', (e) => {
        clearTimeout(mouseLeftTimer); // Clear any existing timer
        const id_field = getDynamicIdField();
        console.log(`dynamic id: ${id_field}`);

        // Start a timer to delay the hover effect by 0.2 seconds
        mouseLeftTimer = setTimeout(() => {
          const features = map.queryRenderedFeatures(e.point, { layers: ['floods_PM'] });
          if (features.length > 0) {
            const feature = features[0];
            const hoveredFeatureProperties = features[0].properties;
            const hoveredFeatureId = hoveredFeatureProperties[id_field]; // Use id_field to get the property dynamically

            // Change fill color of the hovered feature
            map.setPaintProperty('floods_PM', 'fill-color', [
              'case',
              ['==', ['get', id_field], hoveredFeatureId],
              'rgba(255, 0, 0, 0.5)', // Red color for hovered feature
              'rgba(0, 0, 255, 0.1)', // Default color for other features
            ]);

            popup.setLngLat(e.lngLat)
              .setHTML(renderPopupContent(feature))
              .addTo(map);
          }
        }, 200); // 200 milliseconds (0.2 seconds)
      });

      // Function to render popup content
      const renderPopupContent = (feature) => {
        const { properties } = feature;
        if (!properties) return ''; // Return empty string if no properties are available

        // Generate HTML content for popup with all properties
        let contentHTML = '<div>';
        contentHTML += '<h3>Properties</h3>';

        // Loop through each property and add to the popup content
        Object.entries(properties).forEach(([key, value]) => {
          contentHTML += `<p>${key}: ${value}</p>`;
        });

        contentHTML += '</div>';

        return contentHTML;
      };

      // Function to get the dynamic id_field based on the attribute containing 'id'
      const getDynamicIdField = () => {
        const attributeNames = listAttributeNames();
        const idField = attributeNames.find(name => name.toLowerCase().includes('id'));
        if (idField) {
          console.log(`Using id field: ${idField}`);
          return idField;
        }
        console.log('No attribute containing "id" found');
        return attributeNames[0]; // Fallback to the first attribute if no match
      };

      // Function to list all attribute names of the data found
      const listAttributeNames = () => {
        // Query a feature to get its properties
        const features = map.queryRenderedFeatures({ layers: ['floods_PM'] });
        if (features.length > 0) {
          const feature = features[0];
          const attributeNames = Object.keys(feature.properties);
          console.log('Attribute Names:', attributeNames);
          return attributeNames;
        }
        console.log('No features found');
        return [];
      };

      map.on('mouseleave', 'floods_PM', () => {
        // Start a timer to delay the action by 1 second
        mouseLeftTimer = setTimeout(() => {
          // Reset fill color of all features to default after 1 second
          map.setPaintProperty('floods_PM', 'fill-color', 'rgba(0, 0, 255, 0.1)');
          // Remove popup on mouse leave
          popup.remove();
        }, 1000); // 1000 milliseconds (1 second)
      });

      // Cancel the timer if mouse re-enters the mapped area before the timeout
      map.on('mouseenter', 'floods_PM', () => {
        clearTimeout(mouseLeftTimer); // Clear the timer
      });
    }

    // Clean up function when component unmounts
    return () => {
      if (map.getLayer('floods_PM')) {
        map.removeLayer('floods_PM');
      }

      if (map.getSource('floods_PM')) {
        map.removeSource('floods_PM');
      }
    };
  }, [map, url, lyr]);

  return null;
}

export default FloodsLayer_PM;
