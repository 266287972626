import React, { useEffect } from 'react';
import maplibregl from 'maplibre-gl';
import { EllipsisVectorLayer } from 'mapboxgljs-ellipsis';

function FloodsLayer_EL({ map, path, token }) {
  useEffect(() => {
    const defaultPathId = '072e884c-5648-45a2-94cd-a48af34844f2';
    const defaultToken = 'epat_eI7qchD8o4Vn0OS8RJsInKVzTj1lwjicBhUpbGhWq4mmpPJoVJY5dUmhZbhq7BLm';
    let pathId = path
    // Validate inputs and fall back to defaults if necessary
    pathId = typeof path === 'string' && path.length >= 5 ? path : defaultPathId;
    token = typeof token === 'string' && token.length >= 5 ? token : defaultToken;

    // Use default values if url is empty

    const floods_EL = new EllipsisVectorLayer({
      pathId, 
      token,
    });

    floods_EL.addTo(map);

    const popup = new maplibregl.Popup({
      closeButton: false,
      closeOnClick: false,
      maxWidth: 'auto',
    });

    const handleMouseMove = (e) => {
      const id_field = getDynamicIdField(); 
      const features = map.queryRenderedFeatures(e.point, { layers: [`${pathId}_undefined_tiles_fill`] });
      if (features.length > 0) {
        const feature = features[0];
        const hoveredFeatureId = feature.properties[id_field]; // Use dynamic id_field
        const hoveredFeatureProperties = feature.properties;

        map.setPaintProperty(`${pathId}_undefined_tiles_fill`, 'fill-color', [
          'case',
          ['==', ['get', id_field], hoveredFeatureId], // Use dynamic id_field
          'rgba(255, 0, 0, 0.5)', // Red color for hovered feature
          'rgba(0, 0, 255, 0.7)', // Default color for other features
        ]);

        popup.setLngLat(e.lngLat)
          .setHTML(renderPopupContent(feature))
          .addTo(map);
      }
    };

    const handleMouseLeave = () => {
      setTimeout(() => {
        map.setPaintProperty(`${pathId}_undefined_tiles_fill`, 'fill-color', 'rgba(0, 0, 255, 0.7)');
        popup.remove();
      }, 1000);
    };

    map.on('mousemove', `${pathId}_undefined_tiles_fill`, handleMouseMove);
    map.on('mouseleave', `${pathId}_undefined_tiles_fill`, handleMouseLeave);

    map.on('mouseenter', `${pathId}_undefined_tiles_fill`, () => {
      clearTimeout(mouseLeftTimer);
    });

    // Function to list all attribute names of the data found
    const listAttributeNames = () => {
      // Query a feature to get its properties
      const features = map.queryRenderedFeatures({ layers: [`${pathId}_undefined_tiles_fill`] });
      if (features.length > 0) {
        const feature = features[0];
        const attributeNames = Object.keys(feature.properties);
        console.log('Attribute Names:', attributeNames);
        return attributeNames;
      } 
      console.log('No features found');
      return [];
    };

    // Function to get the dynamic id_field based on the attribute containing 'id'
    const getDynamicIdField = () => {
      const attributeNames = listAttributeNames();
      const idField = attributeNames.find(name => name.toLowerCase().includes('id'));
      if (idField) {
        console.log(`Using id field: ${idField}`);
        return idField;
      }
      console.log('No attribute containing "id" found');
      return attributeNames[0]; // Fallback to the first attribute if no match
    };

    const renderPopupContent = (feature) => {
      const { properties } = feature;
      if (!properties) return '';

      let contentHTML = '<div>';
      contentHTML += '<h3>Properties</h3>';

      Object.entries(properties).forEach(([key, value]) => {
        contentHTML += `<p>${key}: ${value}</p>`;
      });

      contentHTML += '</div>';

      return contentHTML;
    };

    return () => {
      ['fill', 'line', 'point', 'border'].forEach((suffix) => {
        const layerId = `${pathId}_undefined_tiles_${suffix}`;
        if (map.getLayer(layerId)) {
          map.removeLayer(layerId);
        }
      });

      if (map.getSource(`${pathId}_undefined-tiles`)) {
        map.removeSource(`${pathId}_undefined-tiles`);
      }

      if (map.getSource(`${pathId}_undefined-source`)) {
        map.removeSource(`${pathId}_undefined-source`);
      }
    };
  }, [map, path, token]); // Re-run effect when map or url changes

  return null; // This component does not render anything directly
}

export default FloodsLayer_EL;
