// import axios from 'axios';
import React, { useState } from 'react';
import './InputPath.styl';

function InputPath(props) {
  return (
    <div className="data-customization-external-geojson-form">

      <div className="data-customization-panel__input__container">
        <input
          type="text"
          value={props.Path}
          onChange={e => props.setPath(e.target.value)}
          className="data-customization-panel__input"
          placeholder={props.placeholder}
        />
      </div>
    
    </div>
  )
}
export default InputPath