import React, { useEffect, useRef } from 'react';
import maplibregl from 'maplibre-gl';

function FloodsLayer_GVT({ map, url }) {
  const popupRef = useRef(null); // Ref for popup instance
  let mouseLeaveTimer; // Timer for mouse leave event

  useEffect(() => {
    const sourceId = 'floods_GVT';

    const generateFeatureIds = (geojson) => {
      geojson.features.forEach((feature, index) => {
        if (!feature.properties.id) {
          feature.properties.id = `feature_${index}`;
        }
      });
      return geojson;
    };

    const resetFeatureState = () => {
      // Reset layer fill color
      map.setPaintProperty('floods_GVT', 'fill-color', 'rgba(0, 0, 255, 0.1)');
      // Remove popup if it exists
      if (popupRef.current) {
        popupRef.current.remove();
      }
    };

    const handleHover = (e) => {
      clearTimeout(mouseLeaveTimer); // Clear any existing timer

      mouseLeaveTimer = setTimeout(() => {
        const features = map.queryRenderedFeatures(e.point, { layers: ['floods_GVT'] });
        const hoveredFeature = features[0]
        // Reset previous feature state
        resetFeatureState();
  
        const hoveredFeatureId = hoveredFeature.properties.id;
  
        // Highlight the hovered feature
        map.setPaintProperty('floods_GVT', 'fill-color', [
          'case',
          ['==', ['get', 'id'], hoveredFeatureId],
          'rgba(255, 0, 0, 0.5)', // Red color for hovered feature
          'rgba(0, 0, 255, 0.1)', // Default color for other features
        ]);
  
        // Show popup for the hovered feature
        popupRef.current = new maplibregl.Popup({
          closeButton: false,
          closeOnClick: false,
          maxWidth: 'auto',
        })
          .setLngLat(e.lngLat)
          .setHTML(renderPopupContent(hoveredFeature.properties))
          .addTo(map);
      }, 200); // 200 milliseconds (0.2 seconds)
    };
    
    const handleMouseLeave = () => {
      // Start a timer to delay resetting the state
      mouseLeaveTimer = setTimeout(() => {
        resetFeatureState();
      }, 1000); // 1000 milliseconds (1 second)
    };

    const handleMouseEnter = () => {
      // Clear the timer when re-entering before timeout completes
      clearTimeout(mouseLeaveTimer);
    };
    
    console.log('url')
    console.log(url)
    // Determine the URL to use
    const defaultUrl = 'https://nazka-vectortiling-ruben.s3.eu-west-1.amazonaws.com/floods.geojson';
    const targetUrl = typeof url === 'string' && url.length > 1 ? url : defaultUrl;
    console.log('target')
    console.log(targetUrl)

    // Load GeoJSON data
    fetch(targetUrl)
      .then(response => response.json())
      .then((geojson) => {
        const geojsonWithIds = generateFeatureIds(geojson);

        // Add GeoJSON source with unique identifiers
        map.addSource(sourceId, {
          type: 'geojson',
          data: geojsonWithIds,
        });

        // Add fill layer
        map.addLayer({
          id: 'floods_GVT',
          type: 'fill',
          source: sourceId,
          paint: {
            'fill-color': 'rgba(0, 0, 255, 0.1)',
            'fill-outline-color': '#777',
          },
        });

        // Handle mousemove event on the layer
        map.on('mousemove', 'floods_GVT', handleHover);

        // Handle mouseleave event on the layer
        map.on('mouseleave', 'floods_GVT', handleMouseLeave);

        // Handle mouseenter event on the layer
        map.on('mouseenter', 'floods_GVT', handleMouseEnter);
      });

    // Cleanup function
    return () => {
      // Remove event listeners and clean up sources/layers
      if (map.getLayer('floods_GVT')) {
        map.removeLayer('floods_GVT');
      }
      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }
    };
  }, [map, url]);

  // Function to render popup content
  const renderPopupContent = (featureProperties) => {
    const keys = Object.keys(featureProperties);
    if (keys.length === 0) return '';

    let contentHTML = '<div>';
    contentHTML += '<h3>Properties</h3>';

    keys.forEach((key) => {
      const value = featureProperties[key];
      contentHTML += `<p>${key}: ${value}</p>`;
    });

    contentHTML += '</div>';

    return contentHTML;
  };

  return null; // This component does not render anything directly
}

export default FloodsLayer_GVT;
