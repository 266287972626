import { applyMiddleware, combineReducers, createStore } from 'redux';
import appWindow from '@utils/AppWindow/app-window.redux.reducers';
import t1 from '@templates/T1/t1.redux.reducers';
import maplibreMap from '@library/MaplibreMap/maplibre-map.redux.reducers';
import searchLocation from './components/LocationSearch/location-search.redux.reducers';

const reducers = combineReducers({ 
  appWindow,
  maplibreMap,
  searchLocation,
  t1,
});

const createStoreArgs = [reducers];

if (import.meta.env.DEV) {
  const { createLogger } = await import('redux-logger');
  const logger = createLogger({
    collapsed: (_getState, _action, logEntry) => !logEntry.error,
  });

  createStoreArgs.push(applyMiddleware(logger));
}

export default createStore(...createStoreArgs);