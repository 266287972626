import maplibregl from 'maplibre-gl';

import * as pmtiles from 'pmtiles';
import sources from './style/style.sources.json';
import layers from './style/style.layers.json';

const protocol = new pmtiles.Protocol();
maplibregl.addProtocol('pmtiles', protocol.tile);

/*  Exports
    ======================================================================================= */
function addSourcesAndLayersFromConfigToMap(mapboxMap) {
  Object.keys(sources).forEach(sourceId => mapboxMap.addSource(sourceId, sources[sourceId]));
  layers.forEach(layer => mapboxMap.addLayer(layer));

  return mapboxMap;
}

function mapFactory(mapEl) {
  const options = { 
    container: mapEl,
    attributionControl: false,
    style: `https://api.maptiler.com/maps/basic-v2-light/style.json?key=${import.meta.env.VITE_MAPTILER_TOKEN}`,
    center: [14.5057515, 46.0569465],
    zoom: 8,
    
  };
  
  return new maplibregl.Map(options);
}

export {
  mapFactory,
  addSourcesAndLayersFromConfigToMap,
}