import { v4 as uuidv4 } from 'uuid';
import { MEDIA_BREAKPOINT_ENTRIES } from '@utils/AppWindow/app-window.constants';

function generateUniqueId() {
  return uuidv4();
}

function isMobile(currentBreakpoint, mobileMaxMediaBreakpoint) {
  const mediaBreakpoints = MEDIA_BREAKPOINT_ENTRIES.map(curr => curr[1]);
  const indexOfCurrent = mediaBreakpoints.indexOf(currentBreakpoint);
  const indexOfMobileMax = mediaBreakpoints.indexOf(mobileMaxMediaBreakpoint);
  
  return (indexOfCurrent <= indexOfMobileMax);
}

function removeExternalLoader() {
  const loaderEl = document.querySelector('.js-external-loader');

  if (!loaderEl) {
    return;
  }

  loaderEl.parentNode.removeChild(loaderEl);
}

function getSizeFromResizeObserverEntry(entry) {
  if (!entry.contentBoxSize) {
    return {
      width: entry.contentRect.width,
      height: entry.contentRect.height,
    };
  }

  const { 
    inlineSize: width, 
    blockSize: height, 
  } = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;

  return { width, height };
}

const tagBody = '(?:[^"\'>]|"[^"]*"|\'[^\']*\')*';

const tagOrComment = new RegExp(
  `${'<(?:'
  + '!--(?:(?:-*[^->])*--+|-?)' // Comment body.
  + '|script\\b'}${tagBody}(/|>[\\s\\S]*?</script\\s*)` // <script...>...</script> & <script.../>
  + `|style\\b${tagBody}(/|>[\\s\\S]*?</style\\s*)` // <style...>...<style> & <style.../>
  + `|iframe\\b${tagBody}(/|>[\\s\\S]*?</iframe\\s*)` // <iframe...>...<iframe> & <iframe.../>
  + ')>',
  'gi',
);

const sanitizeHTML = html => html.replace(tagOrComment, '');

export {
  generateUniqueId,
  isMobile,
  removeExternalLoader,
  getSizeFromResizeObserverEntry,
  sanitizeHTML,
}