const defaultState = {
  map: undefined,
};

function maplibreMap(state = defaultState, action) {
  switch (action.type) {
    case 'SET_MAP': {
      return {
        ...state,
        map: action.payload,
      };
    }
    default:
      return state;
  }
}

export default maplibreMap;