/* eslint-disable */
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@nazka/nazka.mapframe.components';

import { setShowData } from '@templates/T1/t1.redux.actions';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-light-svg-icons';

import './datapanel.styl';

function Datapanel() {
  const showData = useSelector(state => state.t1.showData);
  const dispatch = useDispatch();

  return (
    <div className="datapanel">
      <Button 
        className="datapanel__toggle"
        icon={showData ? faChevronDown : faChevronUp}
        onClick={() => dispatch(setShowData(!showData))}
      />
<div className="datapanel__scroll">
When no input is given in the input box, default data will be mapped. This default data used is
        polygon data with complex geometries of a flood simulation in Ljubljana, which is actual data used
        in one of Nazka’s projects. When an input is given in the input box it will
        be used to access and map the data. Some requirements and assumptions for the input data:
    <br /><br />
    - For geojson-vt: any url to raw geojson data. Works on any GeoJSON <b>polygon</b> data. <b>Creates a new id field</b> to use for interactivity options.
    <br />
    <u>Default</u>: 
    <br />
    <a href="https://nazka-vectortiling-ruben.s3.eu-west-1.amazonaws.com/floods.geojson">
        https://nazka-vectortiling-ruben.s3.eu-west-1.amazonaws.com/floods.geojson
    </a>
    <br />
    <u>Suggestion other data</u>: United States states: 
    <br />
    <a href="https://raw.githubusercontent.com/metabase/metabase/master/resources/frontend_client/app/assets/geojson/us-states.json">
        https://raw.githubusercontent.com/metabase/metabase/master/resources/frontend_client/app/assets/geojson/us-states.json
    </a>
    <br /><br />
    
    - For PMTiles: a url to a raw PMTiles. Giving a <b>layer name is optional</b>. When no input is given in that box, the program maps the first layer it finds in the metadata. For the id field used for hovering it searches for<b> field names containing ‘id’</b>. If it does not find that, it takes the <b>first field as id_field</b>.
    <br />
    When using GitHub you should use the <b>raw.githubusercontent.com/…</b> link so it has the “Access-Control-Allow-Origin: *” header. Using a github.com/&lt;user&gt;/… link does not have that header and will thus result in a CORS error.
    <br />
    <u>Default</u>: 
    <br />
    <a href="https://nazka-vectortiling-ruben.s3.eu-west-1.amazonaws.com/floods2.pmtiles">
        https://nazka-vectortiling-ruben.s3.eu-west-1.amazonaws.com/floods2.pmtiles
    </a>
    <br />
    <u>Suggestion 1</u>: United States counties, <b>no layer name input</b> needed for this one: 
    <br />
    <a href="https://r2-public.protomaps.com/protomaps-sample-datasets/cb_2018_us_zcta510_500k.pmtiles">
        https://r2-public.protomaps.com/protomaps-sample-datasets/cb_2018_us_zcta510_500k.pmtiles
    </a>
    <br />
    <u>Suggestion 2</u>: Buildings of Firenze, give <b>‘buildings’ as layer name input</b>: 
    <br />
    <a href="https://protomaps.github.io/PMTiles/protomaps(vector)ODbL_firenze.pmtiles">
        https://protomaps.github.io/PMTiles/protomaps(vector)ODbL_firenze.pmtiles
    </a>
    <br /><br />
    
    - For MBTiles: a url to hosted MBTiles. Specifying the <b>layer name is obligatory when using user data</b>. For the <b>id field name </b>that is needed for interactivity functionalities such as hovering, the program searches for an <b>attribute name containing ‘id’</b>. If it doesn’t find such attributes, it assumes the <b>first attribute</b> found is an id field. Only works for polygon data.
    <br />
    <u>Default (no layer name input needed)</u>: 
    <br />
    <a href="https://api.maptiler.com/tiles/80d7c3c3-f391-4716-b0a1-a936118595fe/&#123;z&#125;/&#123;x&#125;/&#123;y&#125;.pbf?key=lqzGPutejx80TSYrzflb">
        https://api.maptiler.com/tiles/80d7c3c3-f391-4716-b0a1-a936118595fe/&#123;z&#125;/&#123;x&#125;/&#123;y&#125;.pbf?key=lqzGPutejx80TSYrzflb
    </a>
    <br />
    <u>Suggestion 1</u>: Cadastral polygons of the Netherlands use <b>‘builtup’ as layer name input</b>: 
    <br />
    <a href="https://api.maptiler.com/tiles/nl-cartiqo/&#123;z&#125;/&#123;x&#125;/&#123;y&#125;.pbf?key=lqzGPutejx80TSYrzflb">
        https://api.maptiler.com/tiles/nl-cartiqo/&#123;z&#125;/&#123;x&#125;/&#123;y&#125;.pbf?key=lqzGPutejx80TSYrzflb
    </a>
    <br /><br />
    
    - For Ellipsis Drive: give the <b>pathid and token</b> of your Ellipsis Drive vector data. Can be lines, points, or polygons. For the id field name that is needed for interactivity functionalities such as hovering, the program searches for an attribute name containing ‘id’. If it doesn’t find such attribute, it assumes the first attribute found is an id field.
    <br />
    <u>Default </u> : 
    <u> PathId: </u> 072e884c-5648-45a2-94cd-a48af34844f2 and 
    <u> Token: </u> epat_eI7qchD8o4Vn0OS8RJsInKVzTj1lwjicBhUpbGhWq4mmpPJoVJY5dUmhZbhq7BLm
    <br />
    <u>Suggestion </u>: United States states: 
    <u> PathId: </u> 71e8ec4a-c971-4b51-aaee-29cd7213d3bc and 
    <u> Token: </u> epat_gZvRDBNwuFTosyDedDSGm4qPgU4aA7jZF2L2A4oqKUBPoQl101rAUtej46OMNV57
</div>


    </div>
  )
}

export default Datapanel;