import { useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@nazka/nazka.mapframe.components';

import TranslationsProvider, { LocalizedRoutes } from '@utils/i18n/i18n';
import AppWindow from '@utils/AppWindow/AppWindow';
import ErrorBoundary from '@utils/ErrorBoundary/ErrorBoundary';
// import CookieBar from '@utils/CookieBar/CookieBar';

import renderRoutes from '@templates/T1/t1.routes';

import { baseRoute, outletRoutes } from '@App/app.routes';
import NoMatch from '@App/pages/NoMatch/NoMatch';
import store from '@App/app.store';
import translator from '@App/app.translator';

import theme from '@css/theme.style.json';

import './app.styl';

function App() {
  useEffect(() => document.documentElement.setAttribute('data-app-initialised', true), []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ErrorBoundary>
            <TranslationsProvider translator={translator}>
              <LocalizedRoutes>
                {renderRoutes(baseRoute, outletRoutes)}
                <Route path="*" element={<NoMatch />} />
              </LocalizedRoutes>
              <AppWindow />
              {/* <CookieBar /> */}
            </TranslationsProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
