import { useSelector } from 'react-redux';
import { useState } from 'react';

import './interactive-map.styl';

import MaplibreMap from '@library/MaplibreMap/MaplibreMap'
import LayerSelector from '../../../components/LayerSelector/LayerSelector';

function InteractiveMap() {
  const [isMapLoading, setIsMapLoading] = useState(true)
  const [mapResizeId, setMapResizeId] = useState()
  const showData = useSelector(state => state.t1.showData);

  // const { mediaBreakpoint } = useSelector(state => state.appWindow.resizeProps);

  return (
    <div className={`interactive-map ${showData ? 'interactive-map--has-data' : ''}`}>
      <div className="interactive-map__map">
        <MaplibreMap 
          mapResizeId={mapResizeId}
          onMapInitialized={() => setIsMapLoading(false)}
        />
      </div>
      <div className="layer_wrapper">
        <LayerSelector />
      </div>
    </div>
    
  )
}

export default InteractiveMap;