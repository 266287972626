import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePrev } from '@services/hooks.service';
import Controls from './controls/Controls/Controls';
import { mapFactory, addSourcesAndLayersFromConfigToMap } from './maplibre-map.factory';
import { setMap as setMapInRedux } from './maplibre-map.redux.actions';
import { resizeMap } from './maplibre-map.utils';

import 'maplibre-gl/dist/maplibre-gl.css';
import './maplibre-map.styl';

function MaplibreMap(props) {
  const { mapResizeId, onMapInitialized } = props;
  const [map, setMap] = useState(null);

  const mapRef = useRef(null);
  const prevMapResizeId = usePrev(mapResizeId)
  const prevMap = usePrev(map)
  const searchedLocation = useSelector(state => state.searchLocation.searchLocation);

  const dispatch = useDispatch();
  
  useEffect(() => {
    setMap(mapFactory(mapRef.current));
  }, []);

  useEffect(() => {
    if (map && (map !== prevMap)) {
      map.on('load', () => {
        addSourcesAndLayersFromConfigToMap(map);
        dispatch(setMapInRedux(map));
      });

      onMapInitialized();
    }
  }, [dispatch, map, onMapInitialized, prevMap]);

  useEffect(() => {
    if ((mapResizeId !== prevMapResizeId) && map) {
      resizeMap(map);
    }
  }, [map, mapResizeId, prevMapResizeId]);

  useEffect(() => {
    if (map && searchedLocation) {
      map.fitBounds(searchedLocation.bbox)
    }
  }, [map, searchedLocation])

  return (
    <div ref={mapRef} className="maplibre-map">
      <Controls map={map} />
    </div>
  );
}

export default MaplibreMap;